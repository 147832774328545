import {createRouter, createWebHashHistory} from "vue-router";

const routes = [
    {
        path: '/',
        component: () => import('@/views/HomeView.vue')
    },
    {
        path: '/about',
        component: () => import('@/views/AboutView.vue')
    },
    {
        path: '/privacy',
        component: () => import('@/views/PrivacyView.vue')
    },
    {
        path: '/project-list',
        component: () => import('@/views/ProjectListView.vue')
    },
    {
        path: '/project-info',
        component: () => import('@/views/ProjectInfoView.vue')
    },
    {
        path: "/:pathMatch(.*)*",
        component: () => import('@/views/NotFoundView.vue')
    }
];

const router = createRouter({
    base: process.env.BASE_URL,
    history: createWebHashHistory(),
    scrollBehavior: (to, _, savedPosition) => {
      if (to.hash) return {selector: to.hash};
      if (savedPosition) return savedPosition;
  
      return {x: 0, y: 0};
    },
    routes,
  });
  
  export default router;
  